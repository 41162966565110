<script>
    export default {
        name: "BaseCheckbox",
        props: {
            mobileType: Boolean,
            checked: Boolean,
            text: String,
            textPosition: {
                type: String,
                default: "before",
                validator: (v) => ["before", "after"].includes(v),
            },
        },
        model: {
            prop: "checked",
            event: "change",
        },
    };
</script>

<template>
    <div
        class="checkbox-base-control"
        :class="{ 'checkbox-base-control--mobile': mobileType }"
    >
        <span v-if="text && textPosition === 'before'">{{ text }}</span>
        <label class="base-checkbox" :class="[`text-${textPosition}`]">
            <input
                type="checkbox"
                class="base-checkbox__input visually-hidden"
                :checked="checked"
                @change="$emit('change', !checked)"
            />
            <span class="base-checkbox__custom"></span>
        </label>
        <span v-if="text && textPosition === 'after'">{{ text }}</span>
    </div>
</template>

<style lang="scss">
    .checkbox-base-control {
        font-size: 16px;
        line-height: 24px;
        display: none;
        align-items: center;

        @include bp($bp-desktop-sm) {
            display: flex;
        }

        .base-checkbox {
            display: flex;
            align-items: center;
            max-width: 44px;
            &.text-before {
                margin-left: 12px;
            }
            &.text-after {
                margin-right: 12px;
            }
        }
        .base-checkbox__input {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
            opacity: 0;
            &:checked + .base-checkbox__custom {
                background-color: var(--modal-control-fill);
            }
            &:checked + .base-checkbox__custom:before {
                left: 20px;
            }
            @include bp($bp-desktop-sm) {
                &:focus-visible + .base-checkbox__custom {
                    @include outline;
                }
            }
        }
        .base-checkbox__custom {
            position: relative;
            display: block;
            background: #e6e6e6;
            transition-property: background-color;
            width: 42px;
            height: 24px;
            border-radius: 100px;
            transition: background-color 0.2s linear;
            &:before {
                content: "";
                position: absolute;
                top: 2px;
                left: 2px;
                width: 20px;
                height: 20px;
                transition: left 0.2s linear;
                background: #fff;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }

    .checkbox-base-control--mobile {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 11px 10px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }
</style>
