<script>
    import { mapActions } from "vuex";
    import { capitalizeFirstLetter } from "@/utils";
    import BaseButton from "@main/packages/BaseButton";
    import BaseInput from "@main/packages/BaseInput";
    import BaseCheckbox from "@main/packages/BaseCheckbox";
    import BaseInputPhone from "@main/packages/BaseInputPhone";
    import ModalProfileLogin from "./templates/ModalProfileLogin";
    import ModalProfileRegister from "./templates/ModalProfileRegister";

    export default {
        name: "ModalProfileAuth",
        components: {
            BaseButton,
            BaseInput,
            BaseInputPhone,
            BaseCheckbox,
            ModalProfileLogin,
            ModalProfileRegister,
        },
        data() {
            return {
                type: "login", //login register
            };
        },
        computed: {
            componentName() {
                return "ModalProfile" + capitalizeFirstLetter(this.type);
            },
        },
        methods: {
            ...mapActions("Profile", ["loginProfile"]),
            changeAuthType(type) {
                this.type = type;
            },
        },
    };
</script>

<template>
    <div class="modal-profile-auth">
        <div class="modal-profile-auth__tabs" :class="{ active: type === 'register' }">
            <button
                class="modal-profile-auth__tab outline"
                :class="{ active: type === 'login' }"
                @click="changeAuthType('login')"
            >
                Вход
            </button>
            <button
                class="modal-profile-auth__tab outline"
                :class="{ active: type === 'register' }"
                @click="changeAuthType('register')"
            >
                Регистрация
            </button>
        </div>
        <keep-alive>
            <component :is="componentName"></component>
        </keep-alive>
    </div>
</template>

<style lang="scss">
    .modal-profile-auth {
        padding-top: 20px;
        @include bp($bp-desktop-sm) {
            padding-top: 0;
        }
    }

    .modal-profile-auth__tabs {
        position: relative;
        display: flex;
        margin-bottom: 24px;
        &.active {
            &::before {
                transform: translateX(100%);
            }
        }
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50%;
            height: 4px;
            background-color: $personal-auth-tab--active;
            transition: transform 0.2s ease-in;
        }
    }
    .modal-profile-auth__tab {
        flex-basis: 50%;
        max-width: 50%;
        padding: 12px;
        color: $personal-auth-tab-color;
        font-size: $personal-auth-tab-size;
        font-weight: $personal-auth-tab-weight;
        line-height: 24px;
        border-bottom: 2px solid $personal-auth-tab-border;
        transition: color 0.2s ease-in;
        &.active {
            color: $personal-auth-tab--active;
        }
    }

    .modal-profile-auth__title {
        margin-bottom: 24px;
        color: var(--primary-text);
        font-size: var(--modal-heading-size);
        font-weight: var(--modal-heding-weight);
        line-height: 24px;
    }

    .modal-profile-auth__button {
        width: 100%;
        margin-top: 16px;
    }

    .modal-profile-auth__checkbox {
        margin-top: 16px;
    }

    .modal-profile-auth__state {
        width: 100%;
        margin-top: 24px;
        color: $personal-auth-link-color;
        font-size: 16px;
        font-weight: var(--modal-subheding-weight);
        text-align: center;
    }

    .modal-profile-auth__cities {
        position: relative;
        margin-top: 12px;
    }

    .modal-profile-auth__cities-list {
        position: absolute;
        z-index: 3;
        left: 0;
        width: 100%;
        max-height: 180px;
        background-color: #fff;
        border: 1px solid #f2f2f5;
        overflow: auto;
    }

    .modal-profile-auth__cities-item {
        padding: 10px 12px;
        color: #40404b;
        @include bp($bp-desktop-sm) {
            cursor: pointer;
            transition: background-color 0.2s ease-in;
            &:hover {
                background-color: #f2f2f5;
            }
        }
    }

    .modal-profile-auth__personal-data {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px;

        a {
            color: var(--info-text--dark);
        }
    }
</style>
