<script>
    import { mapActions } from "vuex";
    import BaseButton from "@main/packages/BaseButton";
    import BaseInput from "@main/packages/BaseInput";
    import BaseCheckbox from "@main/packages/BaseCheckbox";
    import BaseInputPhone from "@main/packages/BaseInputPhone";

    export default {
        name: "ModalProfileRegister",
        components: {
            BaseButton,
            BaseInput,
            BaseCheckbox,
            BaseInputPhone,
        },
        data() {
            return {
                register: {
                    name: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    city: "",
                    subscribe: true,
                },
                errorsList: {
                    name: "",
                    phone: "",
                    email: "",
                },
                isVisibleCitiesList: false,
                foundCitiesList: [],
                isLoadingButton: false,
            };
        },
        deactivated() {
            Object.keys(this.errorsList).forEach((key) => (this.errorsList[key] = ""));
        },
        methods: {
            ...mapActions("Profile", ["registerProfile"]),
            submitRegister() {
                this.isLoadingButton = true;
                const request = {
                    ...this.register,
                    subscribe: this.register.subscribe ? 1 : 0,
                };
                this.registerProfile(request).then((response) => {
                    this.isLoadingButton = false;
                    if (response.success) {
                        this.$popup.close("modal-profile-auth");
                        this.$router.push({ name: "ThePersonalMain" });
                        return;
                    }
                    if (response.errors) {
                        for (const key in response.errors) {
                            this.errorsList[key] = response.errors[key];
                        }
                    }
                });
            },
            errorHandler(name) {
                this.errorsList[name] = "";
            },
            getCitiesList() {
                this.$store.rootApi
                    .getListCity({
                        term: this.register.city,
                    })
                    .then((response) => {
                        this.isVisibleCitiesList = true;
                        this.foundCitiesList = response;
                    });
            },
            setAddressCity(name) {
                this.register.city = name;
                this.isVisibleCitiesList = false;
            },
        },
    };
</script>

<template>
    <div class="modal-profile-auth__register modal-profile-register">
        <div class="modal-profile-auth__title">Регистрация</div>
        <form class="modal-profile-auth__form" @submit.prevent="submitRegister">
            <base-input
                v-model="register.name"
                :error-text="errorsList.name"
                name="name"
                placeholder="Имя"
                @error="errorHandler"
            ></base-input>
            <base-input v-model="register.lastName" placeholder="Фамилия"></base-input>
            <base-input-phone
                :value="register.phone"
                :error-text="errorsList.phone"
                placeholder="Номер телефона"
                @input="(e) => (this.register.phone = e)"
                @error="errorHandler"
            ></base-input-phone>
            <base-input
                v-model="register.email"
                :error-text="errorsList.email"
                name="email"
                placeholder="Электронная почта"
                @error="errorHandler"
            ></base-input>
            <div class="modal-profile-auth__cities">
                <base-input
                    v-model="register.city"
                    placeholder="Город"
                    @keyup="getCitiesList"
                ></base-input>
                <ul
                    class="modal-profile-auth__cities-list"
                    v-show="foundCitiesList.length && isVisibleCitiesList"
                >
                    <li
                        class="modal-profile-auth__cities-item"
                        v-for="(city, index) in foundCitiesList"
                        :key="index"
                        @click="setAddressCity(city.name)"
                    >
                        {{ city.name }}
                    </li>
                </ul>
            </div>

            <base-button
                class="modal-profile-auth__button"
                size="lg"
                :variant="$root.$_variant_modal_button || 'v1'"
                :is-loading="isLoadingButton"
            >
                Зарегистрироваться
            </base-button>
            <base-checkbox
                v-model="register.subscribe"
                class="modal-profile-auth__checkbox"
                text="Получать информацию о скидках и секретных распродажах"
                text-position="after"
            >
            </base-checkbox>
        </form>

        <div class="modal-profile-auth__personal-data">
            Нажимая на кнопку, вы даете согласие на
            <a :href="$root.$_documents.personalData" target="_blank">
                обработку своих персональных данных
            </a>
        </div>
    </div>
</template>
