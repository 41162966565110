<script>
    import { mapActions } from "vuex";
    import BaseButton from "@main/packages/BaseButton";
    import BaseInput from "@main/packages/BaseInput";
    import BaseInputPhone from "@main/packages/BaseInputPhone";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import TransitionContainer from "@/components/TransitionContainer";

    export default {
        name: "ModalProfileLogin",
        components: {
            BaseButton,
            BaseInput,
            BaseInputPhone,
            TheSvgIcon,
            TransitionContainer,
        },
        props: {
            isColorPreloader: Boolean,
        },
        data() {
            return {
                state: "phone", // email
                login: {
                    identifier: "",
                    code: "",
                },
                errorMessage: "",
                step: "START",
                resendTimeout: 60,
                resendMultiplier: 1,
                timer: null,
                isVisibleResendButton: false,
                isVisibleInfo: false,
                isLoadingButton: false,
                isLoadingSendCode: false,
            };
        },
        computed: {
            steps() {
                return {
                    start: "START",
                    code: "CODE",
                };
            },
            stateButtonText() {
                return this.state === "phone" ? "Войти по почте" : "Войти по номеру телефона";
            },
            stateTitleText() {
                return this.state === "phone" ? "Вход по номеру телефона" : "Вход по почте";
            },
            resendButtonVariant() {
                const sites = {
                    picooc: "v3",
                    petoneer: "v2",
                    "xd-design": "v2",
                    "mi-ku": "v2",
                    ledger: "v2",
                };
                return sites[this.$root.$_name] || "v3";
            },
        },
        watch: {
            "login.code"(code) {
                if (code.trim().length >= 4) {
                    this.submitLogin();
                }
            },
        },
        mounted() {
            setTimeout(() => {
                this.$refs.loginIdentifier.$el.focus();
            }, 300);
        },
        deactivated() {
            this.clearError();
        },
        methods: {
            ...mapActions("Profile", ["loginProfile"]),
            changeState() {
                this.state === "phone" ? (this.state = "email") : (this.state = "phone");
                this.login.identifier = "";
                this.step = this.steps.start;
                this.clearError();
                clearInterval(this.timer);
            },
            goToStartStep() {
                this.clearError();
                this.step = this.steps.start;
            },
            clearError() {
                this.errorMessage = "";
            },
            startResendCountdown() {
                this.resendMultiplier *= 2;
                this.isVisibleResendButton = false;
                this.timer = setInterval(() => {
                    this.resendTimeout--;
                    if (this.resendTimeout <= 0) {
                        clearInterval(this.timer);
                        this.resendTimeout = 60 * this.resendMultiplier;
                        this.isVisibleResendButton = true;
                    }
                }, 1000);
            },
            submitHandler() {
                if (this.step === this.steps.start) {
                    this.sendAuthCode();
                }
                if (this.step === this.steps.code) {
                    this.submitLogin();
                }
            },
            sendAuthCode() {
                if (this.login.identifier.length) {
                    this.isLoadingButton = true;
                    this.$store.profileApi
                        .sendAuthCode(this.login.identifier)
                        .then((response) => {
                            this.isLoadingButton = false;
                            this.login.code = "";
                            this.clearError();
                            if (response.success) {
                                this.startResendCountdown();
                                this.step = this.steps.code;
                            } else {
                                this.errorMessage =
                                    response.errors?.waitTime ||
                                    "Неверно введены данные аккаунта";
                            }
                        });
                } else {
                    this.errorMessage = "Поле обязательно для заполнения";
                }
            },
            submitLogin() {
                this.isLoadingSendCode = true;
                this.loginProfile(this.login).then((response) => {
                    if (response.success) {
                        setTimeout(() => {
                            this.isLoadingSendCode = false;
                            this.$popup.close("modal-profile-login");
                        }, 500);
                    } else {
                        if (response.errors.code) {
                            this.isLoadingSendCode = false;
                            this.errorMessage = response.errors.code;
                        }
                    }
                });
            },
        },
        filters: {
            formatTime(value) {
                if (value) {
                    const minutes = Math.floor(value / 60);
                    const seconds = value - minutes * 60;
                    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
                    return `${minutes}:${formattedSeconds}`;
                }
            },
        },
    };
</script>

<template>
    <div class="modal-profile-auth__login modal-profile-login">
        <div class="modal-profile-auth__title">{{ stateTitleText }}</div>
        <form class="modal-profile-auth__form" @submit.prevent="submitHandler">
            <template v-if="step === steps.start">
                <base-input-phone
                    v-if="state === 'phone'"
                    :value="login.identifier"
                    :error-text="errorMessage"
                    placeholder="Номер телефона"
                    @input="(e) => (this.login.identifier = e)"
                    @error="clearError"
                    ref="loginIdentifier"
                ></base-input-phone>
                <base-input
                    v-if="state === 'email'"
                    v-model="login.identifier"
                    :error-text="errorMessage"
                    type="email"
                    placeholder="Электронная почта"
                    @error="clearError"
                ></base-input>
                <base-button
                    class="modal-profile-auth__button"
                    size="lg"
                    :variant="$root.$_variant_modal_button || 'v1'"
                    :is-loading="isLoadingButton"
                >
                    Получить код
                </base-button>
            </template>
            <template v-if="step === steps.code">
                <div class="modal-profile-login__code">
                    <div class="modal-profile-login__message">
                        Мы отправили код подтверждения<br />
                        на {{ state === "phone" ? "номер" : "почту" }} {{ login.identifier }}
                        <div
                            class="modal-profile-login__button outline"
                            tabindex="0"
                            @click="goToStartStep"
                        >
                            изменить
                        </div>
                    </div>
                    <div class="modal-profile-login__loader-wrapper">
                        <base-input
                            v-model="login.code"
                            placeholder="Введите код"
                            type="tel"
                            :max-length="4"
                            :error-text="errorMessage"
                            @error="clearError"
                        ></base-input>
                        <preloader
                            :show="isLoadingSendCode"
                            sm-size
                            :is-color="$root.$_color_auth_preloader"
                        ></preloader>
                    </div>

                    <base-button
                        v-if="isVisibleResendButton"
                        tag="div"
                        class="modal-profile-auth__button"
                        size="lg"
                        :variant="resendButtonVariant"
                        @click="sendAuthCode"
                    >
                        Получить новый код
                    </base-button>
                    <div class="modal-profile-login__timer" v-else>
                        Получить новый код через {{ resendTimeout | formatTime }}
                    </div>
                </div>
            </template>
        </form>
        <button class="modal-profile-auth__state outline" @click="changeState">
            {{ stateButtonText }}
        </button>
        <div class="modal-profile-login__info" v-if="step === steps.code">
            <button @click="isVisibleInfo = !isVisibleInfo">
                Не приходит СМС/письмо
                <the-svg-icon
                    name="arrow"
                    :size-w="12"
                    :class="{ 'is-opened': isVisibleInfo }"
                ></the-svg-icon>
            </button>
            <transition-container :show="isVisibleInfo">
                <div class="modal-profile-login__list">
                    <span>Проверьте, что:</span>
                    <ul>
                        <li>ваш телефон включен и находится в зоне приема сети</li>
                        <li>вы ввели правильный номер телефона или адрес электронной почты</li>
                    </ul>
                </div>
                <div class="modal-profile-login__info-message">
                    Иногда СМС или письма приходят не сразу. Если кода нет 10 минут, попробуйте
                    получить новый код
                </div>
            </transition-container>
        </div>
    </div>
</template>

<style lang="scss">
    .modal-profile-login__message {
        font-size: 16px;
        font-weight: var(--modal-subheding-weight);
        line-height: 20px;
    }

    .modal-profile-login__button {
        margin-top: 8px;
        color: $personal-auth-link-color;
        font-size: 16px;
        font-weight: var(--modal-subheding-weight);
        line-height: 24px;
        cursor: pointer;
        @include bp($bp-desktop-sm) {
            margin-top: 0;
        }
    }

    .modal-profile-login__timer {
        margin-top: 16px;
        color: $personal-auth-timer-color;
        font-size: 14px;
        font-weight: var(--modal-subheding-weight);
        line-height: 20px;
        text-align: center;
    }

    .modal-profile-login__info {
        margin-top: 32px;
        text-align: center;
        button {
            color: var(--modal-control-fill);
            font-size: 16px;
            font-weight: var(--modal-subheding-weight);
            line-height: 24px;
            text-align: center;
        }
        svg {
            transform: rotate(-90deg);
            transition: transform 0.2s ease-in;
            &.is-opened {
                transform: rotate(90deg);
            }
        }
    }

    .modal-profile-login__info-message {
        padding: 0 10px;
        color: $personal-auth-text-color;
        font-size: $personal-auth-text-size;
        font-weight: $personal-auth-text-weight;
        line-height: $personal-auth-text-height;
        text-align: left;
    }

    .modal-profile-login__loader-wrapper {
        position: relative;
        margin-top: 12px;
    }

    .modal-profile-login__list {
        margin: 12px 0 16px;
        padding: 0 10px;
        text-align: left;
        ul {
            margin-top: 4px;
        }
        ul,
        span {
            color: $personal-auth-text-color;
            font-size: $personal-auth-text-size;
            font-weight: $personal-auth-text-weight;
            line-height: $personal-auth-text-height;
        }
        li {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 9px;
                left: -10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--modal-control-fill);
            }
        }
    }
</style>
